import request from '@/Utils/request'
import router from '@/router/index'
import { Toast } from 'vant'

// 获取授权链接

export function getWebAuthLink (corpagentid, redirecturi) {
  return request({
    url: '/sidebar/webAuthLink',
    method: 'get',
    params: {
      corp_agent_id: corpagentid,
      redirect_uri: redirecturi
    }
  })
}

// 验证登录信息

export function loginProof (data) {
  return request({
    url: '/sidebar/checkUser',
    method: 'post',
    data
  })
}

// 侧边栏登录

export async function sidebarLogin (data) {
  try {
    const response = await request({
      url: '/sidebar/login',
      method: 'post',
      data
    })
    if (response.status === 200) {
      localStorage.setItem('token', response.data.token)
    }
    return response
  } catch (err) {
    if (err.response.status === 401) {
      if (err.response.data.message === '请确认账号信息，如有疑问请联系管理员') {
        Toast({
          message: err.response.data.message,
          position: 'top'
        })
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
      }
      Toast({
        message: '登录过期,为你重新登录授权,请稍等',
        position: 'top'
      })

      router.push({
        path: 'tiaozhuan'
      })
    }
  }
}
export async function listLogin (data) {
  try {
    const response = await request({
      url: '/sidebar/login',
      method: 'post',
      data
    })
    return response
  } catch (err) {
    if (err.response.status === 401) {
      Toast({
        message: `${err.response.msg}`,
        position: 'top'
      })
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      router.push({
        path: 'listTiaozhuan'
      })
    }
  }
}

// 注册
export function register (data) {
  return request({
    url: '/sidebar/register',
    method: 'post',
    data
  })
}
// 部门结构
export function getDepartments (params) {
  return request({
    url: '/sidebar/departments',
    method: 'get',
    params: params
  })
}
// 获取config接口注入权限验证配置
export function getConfig (params) {
  return request({
    url: '/sidebar/getConfig',
    method: 'get',
    params: params
  })
}

// 获取agentConfig配置
export function getAgentConfig (params) {
  return request({
    url: '/sidebar/getAgentConfig',
    method: 'get',
    params: params
  })
}

// 获取用户信息
export function getUserinfo (params) {
  return request({
    url: '/sidebar/userinfo',
    method: 'get',
    params: params
  })
}

// 获取标签列表

export function getTagList (params) {
  return request({
    url: '/sidebar/tag/list',
    method: 'get',
    params: params
  })
}
// 编辑客户标签

export function editTag (data) {
  return request({
    url: '/sidebar/tag/mark',
    method: 'post',
    data
  })
}

// 获取客户详情
export function getUserList (params) {
  return request({
    url: '/sidebar/external/detail',
    method: 'get',
    params: params
  })
}
// 修改客户信息
export function editRemark (data) {
  return request({
    url: '/sidebar/external/remark',
    method: 'post',
    data
  })
}
// 获取跟进记录
export function recordList (params) {
  return request({
    url: '/sidebar/external/recordList',
    method: 'get',
    params: params
  })
}

// 添加跟进记录
export function addRecord (data) {
  return request({
    url: '/sidebar/external/record',
    method: 'post',
    data
  })
}

// 删除跟进记录
export function delRecord (data) {
  return request({
    url: '/sidebar/external/recordDel',
    method: 'post',
    data
  })
}
// 获取听课记录
export function listenList (params) {
  return request({
    url: '/sidebar/external/listenList',
    method: 'get',
    params: params
  })
}

// 客户已添加员工
export function followUser (params) {
  return request({
    url: '/sidebar/external/followUser',
    method: 'get',
    params: params
  })
}

// 用户画像修改提交
export function userEdit (data) {
  return request({
    url: '/sidebar/external/edit',
    method: 'post',
    data
  })
}

// 客户关系详情
export function getUserRelation (params) {
  return request({
    url: '/sidebar/external/relation',
    method: 'get',
    params: params
  })
}
// 产品列表
export function getProductList (params) {
  return request({
    url: '/sidebar/product/list',
    method: 'get',
    params: params
  })
}

// 产品二维码
export function getProductqrcode (data) {
  return request({
    url: '/sidebar/product/getProductCodeImage',
    method: 'POST',
    data
  })
}

// 商户列表

export function getPayMerchantInfo (params) {
  return request({
    url: '/sidebar/product/getPayMerchantInfo',
    method: 'get',
    params: params
  })
}

// 验证手机号
export function checkMobile (data) {
  return request({
    url: '/sidebar/checkMobile',
    method: 'POST',
    data
  })
}
// 手机号获取user信息
export function getbyMobile (params) {
  return request({
    url: '/sidebar/user/byMobile',
    method: 'get',
    params: params
  })
}

// 获取订单记录

export function getuserOrder (params) {
  return request({
    url: '/sidebar/userOrder',
    method: 'GET',
    params
  })
}
// 搜索非绑定订单
export function searchOrder (params) {
  return request({
    url: '/sidebar/searchOrder',
    method: 'GET',
    params
  })
}

// 非绑定订单绑定

// 合同记录
export function orderBindingUser (data) {
  return request({
    url: '/sidebar/orderBindingUser',
    method: 'POST',
    data
  })
}
export function userContractList (params) {
  return request({
    url: '/sidebar/contract/userContractList',
    method: 'GET',
    params
  })
}

// 合同下载

export function getContractDoucment (params) {
  return request({
    url: '/sidebar/contract/getContractDoucment',
    method: 'GET',
    params
  })
}

// 合同列表

export function contractList (params) {
  return request({
    url: '/sidebar/contract/contractList',
    method: 'GET',
    params
  })
}

// 合同分享
export function getContractCodeImage (data) {
  return request({
    url: '/sidebar/contract/getContractCodeImage',
    method: 'POST',
    data
  })
}

// 转成交
export function getSelectOrder (params) {
  return request({
    url: '/sidebar/transcation/selectOrder',
    method: 'get',
    params
  })
}
// 转成交更多订单
export function getSelectOrderMore (params) {
  return request({
    url: '/sidebar/transcation/moreList',
    method: 'get',
    params
  })
}
// 转成交 最新订单

export function getSelectOrderFirst (params) {
  return request({
    url: '/sidebar/transcation/firstInfo',
    method: 'get',
    params
  })
}
// 撤销/删除转成交

export function getSelectOrderRevoked (data) {
  return request({
    url: '/sidebar/transcation/revoked',
    method: 'POST',
    data
  })
}

// 转成交提交/修改

export function getSelectOrderSubmit (data) {
  return request({
    url: '/sidebar/transcation/submitTransaction',
    method: 'POST',
    data
  })
}

// 直播间学院添加

export function setLiveWhiteList (data) {
  return request({
    url: '/sidebar/setLiveWhiteList',
    method: 'POST',
    data
  })
}

// 编辑客户详情

export function improveUser (data) {
  return request({
    // url: '/sidebar/external/improve',
    url: '/sidebar/member/update',
    method: 'POST',
    data
  })
}

// 编辑客户需求
export function improveNeed (data) {
  return request({
    url: '/sidebar/external/improveNeed',
    method: 'POST',
    data
  })
}

// 更新用户信息

export function updateUser (data) {
  return request({
    url: '/sidebar/user/update',
    method: 'POST',
    data
  })
}

// 用户换绑

export function rebind (data) {
  return request({
    url: '/sidebar/rebind',
    method: 'POST',
    data
  })
}

// 根据member_code 获取用户入营时间等信息
export function getInfoByMemberCode (data) {
  return request({
    url: '/sidebar/member/info',
    method: 'get',
    params: data
  })
}
