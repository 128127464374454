import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import layout from '../views/layout/tabBar.vue'
// import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: layout,
    redirect: '/HomeView',
    children: [
      {
        path: '/HomeView',
        component: HomeView,
        name: '首页'
      },
      {
        path: '/courselist',
        name: 'courselist',
        component: () => import('../views/courseList.vue'),
        meta: {
          title: '商品列表'
        }
      },
      {
        path: '/contract',
        name: 'contract',
        component: () => import('../views/hetongguanli/indexHeTong.vue'),
        meta: {
          title: '合同列表'
        }
      },
      {
        path: '/mymine',
        name: 'mymine',
        component: () => import('../views/mymine/myMine.vue'),
        meta: {
          title: '我的'
        }
      },
      {
        path: '/tools',
        name: 'tools',
        component: () => import('../views/toolsBar.vue'),
        meta: {
          title: '工具'
        }
      }
    ]
  },
  {
    path: '/moreOrder',
    name: 'moreOrder',
    component: () => import('../views/moreOrder/moreOrderindex.vue'),
    meta: {
      title: '成交记录'
    }
  },
  {
    path: '/followUp',
    name: 'followUp',
    component: () => import('../views/genjinjilu/addJiLu.vue'),
    meta: {
      title: '跟进记录'
    }
  },
  {
    path: '/dealRecord',
    name: 'dealRecord',
    component: () => import('../views/deal/dealList.vue'),
    meta: {
      title: '转成交'
    }
  },
  {
    path: '/app',
    name: 'home',
    component: () => import('../App.vue')

  },
  {
    path: '/addTag',
    name: 'addTag',
    component: () => import('../views/addTags.vue')

  },
  {
    path: '/addRecord',
    name: 'addRecord',
    component: () => import('../views/addRecord.vue')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('../views/userInfo.vue')
  },
  {
    path: '/userPor',
    name: 'userPor',
    component: () => import('../views/userPortrayal.vue')
  },
  {
    path: '/tiaozhuan',
    name: 'tiaozhuan',
    component: () => import('../views/tiaoZhuan.vue')
  },

  {
    path: '/listTiaozhuan',
    name: 'listTiaozhuan',
    component: () => import('../views/listTiaoZhuan.vue'),
    meta: {
      title: '跳转页'
    }
  },
  // 直播间客户添加
  {
    path: '/submit',
    name: 'submit',
    component: () => import('../views/components/formSubmit.vue'),
    meta: {
      title: '直播间客户添加'
    }
  },
  // 跳转联系人会话
  {
    path: '/conversation',
    name: 'conversation',
    component: () => import('../views/components/userHerfe.vue'),
    meta: {
      title: '跳转外部联系人id信息'
    }
  },
  // 健康评估表详情
  {
    path: '/healthSheet',
    name: 'healthSheet',
    component: () => import('../views/health_sheet.vue')
  },
  // 健康评估结果
  {
    path: '/healthResult',
    name: 'healthResult',
    component: () => import('../views/health_result.vue')
  },
  // 食疗方案列表
  {
    path: '/schemeList',
    name: 'schemeList',
    component: () => import('../views/scheme_list.vue')
  },
  // 食疗方案列表
  {
    path: '/schemeDetail',
    name: 'schemeDetail',
    component: () => import('../views/scheme_detail.vue')
  },
  // 新增食疗方案第一步
  {
    path: '/addSchemeFirst',
    name: 'schemeStepOne',
    component: () => import('../views/add_scheme_one.vue')
  },
  // 新增食疗方案第二步
  {
    path: '/addSchemeSecond',
    name: 'schemeStepSecond',
    component: () => import('../views/add_scheme_second.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
