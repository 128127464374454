import request from '@/Utils/request'

// 根据客户编码获取方案分页列表
export function getSchemeList (data) {
  return request({
    url: '/sidebar/scheme/member/list',
    method: 'get',
    params: data
  })
}

// 根据方案编号获取方案详情
export function getSchemeDetailById (data) {
  return request({
    url: '/sidebar/scheme/member/detail',
    method: 'get',
    params: data
  })
}

// 根据方案编号阶段列表
export function getStageList (data) {
  return request({
    url: '/sidebar/scheme/stage',
    method: 'get',
    params: data
  })
}
// 根据客户编码和阶段id预览方案
export function getPreviewScheme (data) {
  return request({
    url: '/sidebar/scheme/preview',
    method: 'get',
    params: data
  })
}

// 根据客户编码获取成交商品
export function getGoodsByMemberCode (data) {
  return request({
    // url: '/sidebar/transcation/product',
    url: '/sidebar/scheme/product',
    method: 'get',
    params: data
  })
}

// 提交用户方案
export function submitScheme (data) {
  return request({
    url: '/sidebar/scheme/generate',
    method: 'post',
    data
  })
}

// 用户打卡
export function getClockList (data) {
  return request({
    url: '/sidebar/checkin/info',
    method: 'get',
    params: data
  })
}

// 用户方案课程
export function getCourseList (data) {
  return request({
    url: '/sidebar/product/list',
    method: 'get',
    params: data
  })
}
// 用户绑定课程
export function bindCourseToMemberCode (data) {
  return request({
    url: '/sidebar/scheme/bindStage',
    method: 'post',
    data
  })
}

// 获取工具问卷列表
export function getQuestionnaireList (data) {
  return request({
    url: '/sidebar/share/link_list',
    method: 'get',
    params: data
  })
}

// 用户点击分享 获取分享链接
export function createLinkByShareId (data) {
  return request({
    url: '/sidebar/share/create_share_link',
    method: 'post',
    data
  })
}

// 获取客户动态
export function getUserTrends (data) {
  return request({
    url: '/sidebar/share/user_trends_list',
    method: 'get',
    params: data
  })
}
