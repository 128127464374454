<template>
  <div class="bigDiv" :style="{ minHeight: boxHeight + 'px' }">
    <div v-if="showFlag">
      <div class="text"><img src="../../img/text.png" alt="" /></div>
      <div class="waring">
        <van-notice-bar class="waringBar" wrapable :scrollable="false">
          <template #default>
  <div style="fontWeight: 700;" class="waringTopOng"> <img src="../../img/attention.png" alt=""> 注意事项</div>

            <div>1.一人一号，唯一手机号注册登录</div>
            <!-- <div>2.绑定直播间渠道号，方便确定直播间订单归属</div> -->
            <div>2.绑定直播间个人ID号，方便确定直播间订单归属</div>
            <div>3.请正确选择自己所在的部门及部门类型</div>
            <div>4.初始密码123456，如有注册问题请联系组长</div>
            <div style="color: red; fontWeight: 700;">
注: 如有多个企微，请使用相同手机号提交。
            </div>
          </template>
        </van-notice-bar>
      </div>

      <div class="form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <!-- 通过 pattern 进行正则校验 -->

          <!-- 通过 validator 进行异步函数校验 -->
          <van-field
            v-model="name"
            name="asyncValidator"
            placeholder="请输入姓名"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="mobile"
            @blur="mobileBlur"
            name="asyncValidator"
            placeholder="请输入手机号"
            :rules="[{ required: true }]"
          />
          <!-- <van-field
            v-model="channel_num"
            name="asyncValidator"
            placeholder=" 请输入直播间渠道登录账号(最少八位纯数字)"
        :rules="[{ validator, message: '请输入直播间渠道登录账号' }]"
          /> -->
          <!-- <van-field
            v-model="channel_num"
            name="asyncValidator"
            placeholder="请输入直播间个人ID号(包含数字+大小写字母的10位数)"
        :rules="[{ validator, message: '请输入直播间个人ID号' }]"
          /> -->
          <van-field
            v-model="channel_num"
            name="asyncValidator"
            placeholder="请输入直播间个人ID号(包含数字+大小写字母的10位数)"
          />
          <van-field
            v-model="fieldValue"
            is-link
            readonly
            placeholder="请选择所在部门"
            @click="show = true"
            :rules="[{ required: true }]"
          />
          <van-popup v-model="show" round position="bottom">
            <van-cascader
              v-model="cascaderValue"
              title="请选择所在部门"
              :options="options"
              @close="show = false"
              @finish="onFinish"
              :field-names="fieldNames"
            />
          </van-popup>
          <van-field
            label="请选择部门类型"
            label-width="98px"
            :rules="[{ required: true }]"
          >
            <template #input>
              <van-radio-group
                v-model="type"
                direction="horizontal"
                style="display: flex; flexwrap: nowrap; width: 1100px"
              >
                <van-radio name="1">前端</van-radio>
                <van-radio name="2">后端</van-radio>
                <van-radio name="0">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div>
            <van-button
              round
              block
              type="info"
              native-type="submit"
              class="subbtn"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
    <div
      v-else-if="!showFlag && !userCode"
      :style="{ height: boxHeight + 'px' }"
    >
      <div class="goBack" @click="goBack">
        <img src="../../img/jian.png" alt="" />
      </div>
      <div class="textA"><img src="../../img/queren.png" alt="" /></div>
      <div class="waringA"><img src="../../img/waringa.png" alt="" /></div>
      <div class="card">
        <div class="names">
          <div class="tp"><img src="../../img/idCard.png" alt="" /></div>
          <div class="tx">
            {{ type == 1 ? "前端" : type == 2 ? "后端" : "无" }}
          </div>
        </div>
        <div class="names">
          <div class="tp"><img src="../../img/users.png" alt="" /></div>
          <div class="tx">{{ name }}</div>
        </div>
        <div class="names">
          <div class="tp"><img src="../../img/phones.png" alt="" /></div>
          <div class="tx">{{ mobile }}</div>
        </div>
        <div class="names">
          <div class="tp"><img src="../../img/path.png" alt="" /></div>
          <div class="tx">{{ fieldValue }}</div>
        </div>
      </div>
      <div>
        <van-button type="primary" block class="btns" round @click="submit"
          >确认</van-button
        >
      </div>
    </div>
    <div v-if="userCode" style="overflow: hidden">
      <div class="goBackA" @click="goBackA">
        <img src="../../img/jian.png" alt="" />
      </div>
      <div class="userCode">
        <img src="../../img/userCode.png" alt="" />
      </div>

      <div class="code-card">
        <div style="display: flex">
          <div class="imgs"><img src="../../img/userCard.png" alt="" /></div>
          <div class="code">{{ this.user_code }}</div>
        </div>
      </div>
      <div class="codeWaring">
        <div style="display: flex">
          <div class="img">
            <img src="../../img/attention.png" alt="" />
          </div>
          <div class="type">注意事项</div>
        </div>
        <ul>
          <li>
            <div class="texts">
              注册成功，您的工号是
              <strong style="font-size: 16px">{{ this.user_code }}</strong>
              请务必牢记。<strong>建议您截图保存。</strong>
            </div>
          </li>
          <!-- <li>
                 <div class="texts">
          请尽快联系部门负责人，在直播间后台重新创建渠道，渠道账号设置为工号，后续将用新的
          <strong>渠道账号({{ this.user_code }})</strong>
          登录渠道后台分享直播间，如不设置，直播间订单将无法确定业绩。(如已设置请忽略)
        </div>
          </li> -->
          <li>
            <div class="texts">
              如需给客户发送商品支付链接，请在侧边栏“商品列表”分享，否则无法确定业绩。
            </div>
          </li>
          <li>
            <div class="texts">有任何疑问可在企微里联系技术部同事</div>
          </li>
        </ul>
      </div>
      <div>
        <van-button type="primary" block round class="subbtnA" @click="submitA"
          >确认</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, Cascader, RadioGroup, Radio, Dialog, Toast } from 'vant'
// userCode
import { getDepartments, register, checkMobile } from '@/api/qywxSidebar'
import Vue from 'vue'

Vue.use(Dialog)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Cascader)
Vue.use(Form)
Vue.use(Field)
export default {
  props: {
    corp_agent_id: {
      type: String,
      default: ''
    },
    work_userid: {
      type: String,
      default: ''
    },
    user_code: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      userCode: false,
      showFlag: true,
      boxHeight: 0,
      fieldNames: {
        text: 'name',
        value: 'id',
        children: 'child'
      },
      name: '',
      mobile: '',
      department_id: '',
      channel_num: '',
      type: '1',
      show: false,
      fieldValue: '',
      cascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: []
    }
  },
  methods: {
    // 直播间渠道号校验
    validateChannelNum (rule, value, callback) {
      const pattern = /^[0-9]{8,}$/
      if (value && !pattern.test(value)) {
        callback(new Error('请输入至少八位数字'))
      } else {
        callback()
      }
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    // validator (val) {
    //   if (/^\d{8,24}$/.test(val)) {
    //     return /^\d{8,99}$/.test(val)
    //   } else {
    //     Toast.fail('直播间渠道登录账号最少八位纯数字!')
    //     return /^\d{8,24}$/.test(val)
    //   }
    // },
    // 直播间个人ID号
    validator (val) {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10}$/
      if (regex.test(val)) {
        return regex.test(val)
      } else {
        Toast.fail('直播间个人ID号必须为包含数字、大小写字母的10位数,请检查输入是否有误!')
        return regex.test(val)
      }
    },
    // 异步校验函数返回 Promise
    asyncValidator (val) {
      return /\d{6}/.test(val)
    },
    async onFailed (errorInfo) {
      if (errorInfo) {
        return false
      } else {
        this.showFlag = false
      }
    },
    async mobileBlur () {
      const query = {
        mobile: this.mobile
      }
      const data = await checkMobile(query)
      if (data.data.user_code) {
        Dialog.confirm({
          title: '温馨提示',
          message: data.msg
        })
          .then(() => {})
          .catch(() => {
            this.$emit('update:user_code', data.data.user_code)
            this.fieldValue = data.data.department_name
            this.type = data.data.type
            this.showFlag = false
            this.show = false

            this.mobileLogin()
          })
      }
    },
    onSubmit () {
      this.showFlag = false
    },
    onFinish ({ selectedOptions }) {
      this.show = false
      this.fieldValue = selectedOptions.map((option) => option.name).join('/')
      this.department_id = selectedOptions[selectedOptions.length - 1].id
    },
    async getDepartments () {
      const { data } = await getDepartments()
      this.options = data
    },
    //
    goBack () {
      this.showFlag = true
    },
    goBackA () {
      this.userCode = false
    },
    async submit () {
      if (this.user_code === '') {
        const query = {
          name: this.name,
          mobile: this.mobile,
          department_id: this.department_id,
          type: this.type,
          work_userid: this.work_userid,
          corp_agent_id: this.corp_agent_id,
          channel_num: this.channel_num
        }
        const data = await register(query)
        this.$emit('update:user_code', data.data.user_code)
      }
      this.userCode = true
    },
    async mobileLogin () {
      const query = {
        name: this.name,
        mobile: this.mobile,
        // department_id: this.department_id,
        type: this.type,
        work_userid: this.work_userid,
        corp_agent_id: this.corp_agent_id
      }
      const data = await register(query)
      this.$emit('update:user_code', data.data.user_code)
      this.userCode = true
    },
    async submitA () {
      this.$parent.loginAll()
    },
    setBoxHeight () {
      this.boxHeight = window.innerHeight // 获取当前窗口高度并设置盒子高度
    }
  },
  created () {
    this.getDepartments()
  },
  mounted () {
    this.setBoxHeight() // 初始化盒子高度
    window.addEventListener('resize', this.setBoxHeight) // 监听窗口大小变化
  },
  watch: {},
  computed: {},
  filters: {}
}
</script>

<style scoped lang="scss">
.bigDiv {
  min-height: 268px;
  width: 100%;
  background-image: url(../../img/bgc.png);
  background-size: cover;
  background-repeat: no-repeat; /* 不平铺 */
  overflow: hidden;
  .text {
    width: 220px;
    height: 35px;
    margin-top: 15%;
    margin-left: 3%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .textA {
    width: 220px;
    height: 40px;
    margin-top: 1%;
    margin-left: -3.5%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .waring {
    width: 350px;
   margin: 10px auto;
.waringBar{
  text-align: left;
background: rgba(255, 245, 230, 0.7);
color: rgba(235, 141, 0, 1);
border: 0.4px solid rgba(235, 141, 0, 1);
border-radius: 6px;
.waringTopOng{
  img{
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
}
}
  }
  .waringA {
    width: 310px;
    margin-top: 5%;
    margin-left: 8%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .form {
    width: 350px;
    margin-top: 2%;
    margin-left: 3%;
    .van-form {
      .van-field {
        margin: 15px 0;
        height: 40px;
        border-radius: 8px;
      }
      .van-radio {
        width: 60px;
      }
      .van-cell {
        padding: 10 2px !important;
      }
      .van-field__label {
        color: #a1a1a1 !important;
        width: 1200px !important;
      }
      .van-cell__title {
        color: #a1a1a1 !important;
        width: 1200px !important;
      }
    }
  }
  .van-field__label {
    color: #a1a1a1 !important;
    width: 1200px !important;
  }
  .van-cell__title {
    color: #a1a1a1 !important;
    width: 1200px !important;
  }
  .card {
    overflow: hidden;
    text-align: left;
    width: 315px;
    height: 146px;
    opacity: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    margin: 0 auto;
    margin-top: 5%;
    .names {
      margin: 15px;
      display: flex;
      height: 20px;
      .tp {
        width: 20px;
        height: 18px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .tx {
        margin-left: 10px;
      }
    }
  }
  .goBack {
    width: 80px;
    height: 40px;
    margin-top: 18%;
    margin-left: 3%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .btns {
    width: 315px;
    margin: 40px auto;
    background-color: #3975c6;
    border: none;
    margin-bottom: 70px;
  }
  .van-cell .van-field {
    padding: 0 !important;
  }
  .subbtn {
    width: 350px;
    margin: 32px auto;
    background-color: #3975c6;
    border: none;
    margin-bottom: 70px;
  }
  .userCode {
    width: 120px;
    height: 24px;
    margin-top: 2%;
    margin-left: 10%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .codeWaring {
    box-sizing: border-box;
    overflow: hidden;
    width: 330px !important;
    min-height: 55px;
    background-color: rgba($color: #fff5e6, $alpha: 0.7);
    border: 0.5px solid #eb8d00;
    color: #eb8d00;
    margin: 15px auto;
    margin-top: 6%;
    text-align: left;
    border-radius: 6px;
    padding: 8px;
    .img {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .type {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 700;
    }
    .texts {
      text-align: left;
      font-size: 14px;
      margin-left: 10%;
      margin-top: 2%;
      margin-bottom: 1%;
      line-height: 1.5em;
      font-family: "Microsoft YaHei", Arial, sans-serif;
      strong {
        color: #ff3508;
      }
    }
  }
  .code-card {
    width: 330px;
    height: 55px;
    opacity: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    margin: 0 auto;
    margin-top: 8%;
    line-height: 55px;
    .imgs {
      margin-top: 8px;
      margin-left: 10px;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .code {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .subbtnA {
    width: 330px;
    margin: 32px auto;
    background-color: #3975c6;
    border: none;
    margin-bottom: 70px;
  }
  .goBackA {
    width: 80px;
    height: 40px;
    margin-top: 18%;
    margin-left: 3%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
