import request from '@/Utils/request'

// 根据客户编码获取问卷列表
export function getQuestionList (data) {
  return request({
    url: '/sidebar/questionnaire/list',
    method: 'get',
    params: data
  })
}
// 根据问卷id获取问卷详情
export function getDetailById (data) {
  return request({
    url: '/sidebar/questionnaire/detail',
    method: 'get',
    params: data
  })
}
// 根据问卷id获取问卷结果
export function getResultById (data) {
  return request({
    url: '/sidebar/questionnaire/results',
    method: 'get',
    params: data
  })
}

// 重置客户问卷
export function handleReset (data) {
  return request({
    url: '/sidebar/questionnaire/reset',
    method: 'post',
    data
  })
}
