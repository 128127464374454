import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Cell, Checkbox, CheckboxGroup, Dialog, Tab, Tabs, Tag, ActionSheet, Step, Steps, Skeleton, Field, CellGroup, Button, NavBar, Overlay, Loading, Picker, RadioGroup, Radio, Cascader, DatetimePicker, Popup, NoticeBar, Col, Row, List, Tabbar, TabbarItem, Search, Toast, Popover, Uploader, Icon, Empty, Calendar, Stepper } from 'vant'

import 'vant/lib/index.css'
import './style/normalize.css'
import 'element-ui/lib/theme-chalk/index.css'
import { Select, Option, OptionGroup, DatePicker, TimePicker, Image } from 'element-ui'
import 'element-ui/lib/theme-chalk/select.css'
// 节流 防抖
import * as utils from '@/Utils/throttleClick'

Vue.use(utils)// 引用这个utils
Vue.prototype.$utils = utils // 全局请求方法

// 组件
Vue.use(Uploader)
Vue.use(Image)
Vue.use(TimePicker)
Vue.use(DatePicker)
Vue.use(CellGroup)
Vue.use(Popover)
Vue.use(Search)
Vue.use(List)
Vue.use(Toast)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Cell)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(NoticeBar)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Cascader)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Picker)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(NavBar)
Vue.use(Button)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Skeleton)
Vue.use(Step)
Vue.use(Steps)
Vue.use(ActionSheet)
Vue.use(Tag)
Vue.use(Tab)
Vue.use(Tabs) // 引入 Select 组件的样式
Vue.use(Option)
Vue.use(OptionGroup)
Vue.component(Select.name, Select)
Vue.use(Icon)
Vue.use(Empty)
Vue.use(Calendar)
Vue.use(Stepper)

// 全局注册
Vue.use(Dialog)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
