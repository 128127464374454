<template>
  <div id="app">
    <div>
      <router-view />

    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      boxHeight: 0,
      active: 0,
      isLogin: true
    }
  },
  created () {
    const data3 = sessionStorage.getItem('data3')
    if (data3) {
      this.isLogin = true
    } else {
      this.isLogin = false
    }
  },
  mounted () {
    this.setBoxHeight() // 初始化盒子高度
    window.addEventListener('resize', this.setBoxHeight) // 监听窗口大小变化
  },
  methods: {
    setBoxHeight () {
      this.boxHeight = window.innerHeight // 获取当前窗口高度并设置盒子高度
    }

  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f0f3fa;
  .imgsA {
    width: 25px;
  }
}

</style>
