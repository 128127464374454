import { Dialog, Toast, ImagePreview } from 'vant'
// ImagePreview
import '../../style/vantTap.scss'

import {
  getWebAuthLink,
  sidebarLogin,
  getConfig,
  getAgentConfig,
  getUserinfo,
  getUserRelation,
  getUserList,
  listenList,
  recordList,
  followUser,
  addRecord,
  delRecord,
  loginProof,
  getuserOrder,
  userContractList,
  getContractDoucment,
  searchOrder,
  getProductList,
  orderBindingUser,
  getSelectOrderFirst,
  getSelectOrderRevoked,
  improveNeed,
  getInfoByMemberCode
} from '@/api/qywxSidebar'
import { getQuestionList, handleReset } from '@/api/questionnaire'
import { getSchemeList, getClockList, getCourseList, bindCourseToMemberCode, getGoodsByMemberCode, getUserTrends } from '@/api/scheme'

import loginInfo from '../components/loginInfo.vue'
// 创建第一个外部脚本标签
const script1 = document.createElement('script')
script1.src = '//res.wx.qq.com/open/js/jweixin-1.2.0.js'
document.head.appendChild(script1)

// 创建第二个外部脚本标签
const script2 = document.createElement('script')
script2.src = 'https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js'
document.head.appendChild(script2)
export default {
  props: {},
  components: {
    loginInfo
  },
  data () {
    return {
      boxHeight: 0,
      xiuqiuneir: '',
      addXiuqiushow: false,
      userInfoshow: true,
      showNeed: true,
      followupRecordShow: false,
      userPortraitShow: true,
      actionShow: false,
      phoneShow: false,
      title: '',
      active: 0,
      code: '',
      corp_agent_id: '',
      ConfigData: '',
      agentConfigData: '',
      showRow: false,
      external_userid: '',
      userWorkInfo: null,
      userRelation: {
        name: ''
      },
      userRelationMark: '',
      userRelationMarkLast: [],
      userPortrait: {},
      followUserList: [],
      moreList: [],
      followupRecord: [],
      LectureRecord: [],
      followupRecordValue: '',
      show: true,
      tabsFlag: false,
      viewFlag: true,
      work_userid: '',
      user_code: '',
      orderShow: true,
      orderList: [],
      loading: false,
      finished: false,
      total: '',
      page: '1',
      limit: '10',
      listenfinished: false,
      listenloading: false,
      listenLimit: '10',
      listenPage: '1',
      listenTotal: '',
      hetongshow: true,
      Pcflag: '',
      hetongjilushowflag: true,
      ContractList: [],
      trade_no_show: '',
      bindOrderShow: false,
      radio: '',
      name: '',
      querenSHow: false,
      demos: [

      ],
      bindOrdertotal: '',
      bindOrderloading: false,
      bindOrderfinished: false,
      bindOrderPage: 0,
      bindOrderlimit: 10,
      isApp: false,
      bindOrderQuery: {
        id: '',
        trade_no_show: '',
        user_code: '',
        product_id: '',
        external_userid: '',
        payment_type: ''
      },
      moreClick: false,
      moreMsgList: [],
      payPrise: '',
      paytypeRadio: false,
      paytypeRadioA: false,
      moreOrders: true,
      dealListFister: {},
      showPopover: false,
      srcList: [],
      infoActive: 0, // 资料下的tab
      formList: [], // 填写表单列表
      memberCode: '2024071015064004', // 客户编码 目前先写死 后期从/sidebar/external/relation接口中获取
      customerInfo: {},
      schemeList: [], // 食疗方案列表
      dateType: [
        { type: 'date', label: '今天', isActive: true },
        { type: 'day3', days: '3', label: '最近3天', isActive: false },
        { type: 'day7', days: '7', label: '最近7天', isActive: false },
        { type: 'time', label: '自定义', isActive: false }
      ],
      currentDateType: 'date',
      clockInInfo: {
        list: [],
        total: 0
      },
      // clockInInfo: {
      //   list: [
      //     {
      //       isExpand: false,
      //       weight: {
      //         bmi: '17.70',
      //         weight: '60.70',
      //         img_url: 'https://wx.qlogo.cn/mmhead/LIND77SSex8SoJFibVBAdvswnkgdoiaO8YjAicPIJhBORdMV2hFS16J9Gw2uZGzxYGYMqxOwZwTSRE/0',
      //         weight_change: '-0.10'
      //       },
      //       meals: [
      //         {
      //           foods: [
      //             {
      //               weight: '100',
      //               food_name: '麦片'
      //             }
      //           ],
      //           meals: '早餐',
      //           custom_food: '咖啡300g',
      //           img_url: 'https://wx.qlogo.cn/mmhead/LIND77SSex8SoJFibVBAdvswnkgdoiaO8YjAicPIJhBORdMV2hFS16J9Gw2uZGzxYGYMqxOwZwTSRE/0'
      //         },
      //         {
      //           foods: [
      //             {
      //               weight: '300',
      //               food_name: '鸡公煲'
      //             }
      //           ],
      //           meals: '午餐',
      //           custom_food: ''
      //         }
      //       ],
      //       water: {
      //         water: 600
      //       },
      //       sleep: {
      //         bedtime: '2024-06-18 22:30',
      //         sleep_time: '8小时30分',
      //         sleep_label: [
      //           '神清气爽'
      //         ],
      //         wakeup_time: '2024-06-19 07:00'
      //       },
      //       sport: [
      //         {
      //           heat: '100',
      //           img_url: '',
      //           quantity: '20',
      //           sport_id: '2',
      //           sport_name: '跑步'
      //         }
      //       ],
      //       date: '2024-07-08'
      //     }
      //   ],
      //   total: 6
      // }, // 打卡的内容
      showClockPop: false, // 打卡自定义选择时间范围弹出层
      startDate: '', // 打卡区间开始时间
      endDate: '', // 打卡区间结束时间
      currentInput: 'first',
      minDate: new Date(new Date().getFullYear() - 5, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
      showstart: false, // 开始时间选择器
      showend: false, // 结束时间选择器
      startDatePicker: null,
      endDatePicker: null,
      productList: [{ id: 1, store_name: '元满健康食疗服务-身体焕活-90天', price: 12900 }], // 商品列表
      currentProductId: '', // 当前用户选择的商品
      showProductPop: false, // 是否显示选择商品弹出层
      bindBtnDisable: false,
      binBtnLoading: false,
      currentProduct: {},
      userProductList: [],
      userTrends: {
        list: [],
        total: 0
      },
      pageTrendsRequest: {
        page: 1,
        limit: 10
      },
      showLoadMore: false, // 是否显示更多按钮
      customerTrendsList: []
    }
  },
  async created () {
    this.active = this.$route.query.active || 0

    const ids = this.$route.query.corp_agent_id
    // eslint-disable-next-line eqeqeq
    if (ids == 6) {
      this.$router.replace({
        path: '/submit'
      })
      return
    }
    this.show = true
    const url = window.location.href
    this.getCodeAndId(url)
    const agentId = JSON.parse(sessionStorage.getItem('corp_agent_id'))
    if (agentId && agentId !== '' && agentId !== null) {
      console.log(1)
    }
    // 判断token
    const token = localStorage.getItem('token')
    // eslint-disable-next-line camelcase
    const token_type = localStorage.getItem('token_type')
    // eslint-disable-next-line camelcase
    if (token && token_type) {
      this.getCodeAndId(url)
      const data1 = JSON.parse(sessionStorage.getItem('data1'))
      const data2 = JSON.parse(sessionStorage.getItem('data2'))
      const data3 = sessionStorage.getItem('data3')
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo && userInfo !== '') {
        this.userWorkInfo = userInfo
      }
      if (
        data3 &&
        Object.keys(data1).length !== 0 &&
        Object.keys(data2).length !== 0
      ) {
        this.ConfigData = data1
        this.agentConfigData = data2
        // 外部联系人ID
        this.external_userid = data3
        localStorage.getItem('userInfo')
        this.listInfo()
      } else {
        await this.configAll()
        await this.EnterpriseWeChat()
      }
    } else {
      const url = window.location.href
      this.getUrlAgendId(url)
    }
  },

  mounted () {
    this.isQyweixin()

    this.setBoxHeight() // 初始化盒子高度
    window.addEventListener('resize', this.setBoxHeight) // 监听窗口大小变化
  },
  methods: {
    // 点击预览跟进图片
    getImg (img, bigindxe, index) {
      // console.log(this.followupRecord)
      this.srcList = this.followupRecord[bigindxe].image
      // console.log(index)
      // ImagePreview({ images: this.followupRecord[bigindxe].image, closeable: true, startPosition: index, showIndicators: true })
    },
    // 跟进记录
    addFleow () {
      this.$router.push({
        path: '/followUp'
      })
    },
    // 客户需求
    addXiuqiu () {
      this.addXiuqiushow = true
    },
    xvqiuClose () {
      this.addXiuqiushow = false
    },
    async  xvqiuAdd () {
      const query = {
        external_userid: this.external_userid,
        userid: this.userWorkInfo.work_userid,
        need: this.xiuqiuneir
      }
      await improveNeed(query)
      this.getUserRelation()
      Toast('编辑成功!')
      this.addXiuqiushow = false
    },
    // 转成交最新订单
    async  getSelectOrderFirst () {
      const query = {
        external_userid: this.external_userid

      }
      const { data } = await getSelectOrderFirst(query)
      this.dealListFister = data
    },

    // 编辑转成交最新订单
    dealFisterEdit () {
      sessionStorage.setItem('editOrderitem', JSON.stringify(this.dealListFister))
      this.$router.push({
        path: '/dealRecord',
        query: {
          request_type: 2
        }
      })
    },
    editdealOrder (item) {

    },
    // 删除/撤销转成交最新订单
    dealFisterDel (type) {
      const title = type === 1 ? '撤回本次转成交' : '删除本次转成交'
      const message = `<span style="color:red;">${this.dealListFister.product_name}${this.dealListFister.periods}期(${this.dealListFister.total_price}元)${this.dealListFister.status_name}</span>,请确认是否${type === 1 ? '撤回' : '删除'}!`
      Dialog.confirm({
        title,
        message
      })
        .then(() => {
          const query = {
            external_userid: this.external_userid,
            transaction_id: this.dealListFister.id,
            action_type: type
          }
          getSelectOrderRevoked(query).then(() => {
            Toast(`${type === 1 ? '撤回' : '删除'}成功`)
            this.orderList = []
            this.getSelectOrderFirst()
          })
        })
        .catch(() => {
        })
    },
    // 听课记录 更多
    moreShow (item) {
      this.moreMsgList = item.chat_msg
      this.moreClick = true
    },
    // 判断pc/app
    isQyweixin () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ) != null
      if (flag) {
        this.Pcflag = 'app'
        this.isApp = true
      } else {
        this.Pcflag = 'pc'
      }
    },

    async downLoadB (item) {
      this.$toast.loading('请稍等...')

      const query = {
        id: item.id
      }
      const { data } = await getContractDoucment(query)

      // eslint-disable-next-line no-undef
      wx.previewFile({
        name: `${item.contract_name}.pdf`,
        url: data.url,
        size: data.fileSize
      })
      this.$toast.clear()
    },

    // 下载
    async downLoad (id) {
      this.$toast.loading('请稍等...')
      const query = {
        id
      }
      const { data } = await getContractDoucment(query)

      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = data.url
      link.setAttribute('download', '电子合同')
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // 下载完成移除元素
      this.$toast.clear()
    },

    getFileSize (url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('HEAD', url)
        xhr.onreadystatechange = function () {
          if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
              const contentLength = xhr.getResponseHeader('Content-Length')
              resolve(parseInt(contentLength))
            } else {
              reject(new Error('Failed to fetch file size'))
            }
          }
        }
        xhr.send()
      })
    },
    // // 下载权限注入
    async getDownLoad () {
      const that = this
      this.hetongjilushowflag = false
      const agentConfig = this.agentConfigData
      const Config = this.ConfigData
      // eslint-disable-next-line no-undef
      await wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: Config.appId, // 必填，企业微信的corpID
        timestamp: Config.timestamp, // 必填，生成签名的时间戳
        nonceStr: Config.nonceStr, // 必填，生成签名的随机串
        signature: Config.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: Config.jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })

      // eslint-disable-next-line no-undef
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })

      // eslint-disable-next-line no-undef
      await wx.ready(function () {
        // eslint-disable-next-line no-undef
        wx.agentConfig({
          corpid: agentConfig.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentConfig.agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: agentConfig.timestamp, // 必填，生成签名的时间戳
          nonceStr: agentConfig.nonceStr, // 必填，生成签名的随机串
          signature: agentConfig.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: agentConfig.jsApiList, // 必填，传入需要使用的接口名称
          success: function (res) {
            // 回调
            that.hetongjilushowflag = true
            // eslint-disable-next-line no-undef
          },
          fail: async function (res) {
            await that.configAll()
            that.getDownLoad()
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          }
        })

        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      })
    },
    // 订单显示

    async  onLoad () {
      this.limit = 10
      this.page = +this.page + 1
      await this.getuserOrder()
      // this.loading = false
      if (this.orderList.length >= this.total) {
        this.finished = true
      }
    },

    // 转成交列表跳转
    getMoreOrder () {
      this.$router.push({
        path: '/moreOrder',
        query: {

        }
      })
    },
    // 转成交操作跳转
    getDealRecord () {
      this.$router.push({
        path: '/dealRecord',
        query: {

        }
      })
    },
    setBoxHeight () {
      this.boxHeight = window.innerHeight // 获取当前窗口高度并设置盒子高度
    },
    // tab栏切换功能
    beforeChange (index) {
      console.log(index, 'index')
      // 返回 false 表示阻止此次切换
      if (index === '资料') {
        this.getUserRelation()
        this.getFollowUser()
      }
      if (index === '订单') {
        this.page = 1
        this.total = 10
        this.orderList = []
        this.getuserOrder()
      } else if (index === '打卡') {
        console.info('用户点击打卡', index)
        this.loadClockList({
          member_code: this.userRelation.member_code,
          external_userid: this.external_userid,
          date: this.formatterDate(new Date()),
          day: '',
          time: []
        })
      } else if (index === '合同') {
        this.$toast.loading('请稍等...')

        this.userContractList()
        this.isQyweixin()
        this.configAll()
        this.$toast.clear()

        this.getDownLoad()
      } else if (index === '跟进') {
        this.getRecordList()
      } else if (index === '听课') {
        this.getListenList()
      } else if (index === 6) {
        this.getUserList()
      }
      this.active = index
    },

    // 合同记录
    async userContractList () {
      const query = {
        external_userid: this.external_userid
      }
      const { data } = await userContractList(query)
      this.ContractList = data
    },
    // 展示不同数据
    showMore (type) {
      if (type === 'user') {
        this.title = '已添加员工'
        this.moreList = this.followUserList
      } else {
        this.title = '已加入的群'
      }
      this.actionShow = true
    },

    // 获取url中的参数
    getCodeAndId (urls) {
      const url = urls
      const ids = JSON.parse(sessionStorage.getItem('corp_agent_id'))
      // eslint-disable-next-line eqeqeq
      if (ids == 6) {
        this.$router.replace({
          path: '/submit'
        })
      }
      if (ids && ids !== 0) {
        this.corp_agent_id = ids
      } else {
        const urlParams = new URLSearchParams(new URL(url).search)
        // 从 URL 参数中获取 corp_agent_id 和 code 的值

        sessionStorage.setItem('code', JSON.stringify(urlParams.get('code')))
        const id = Number(urlParams.get('corp_agent_id'))
        // eslint-disable-next-line eqeqeq
        if (id == 6) {
          this.$router.replace({
            path: '/submit'
          })
        }
        if (id && id !== 0) {
          this.corp_agent_id = Number(urlParams.get('corp_agent_id'))
          this.code = urlParams.get('code')
          sessionStorage.setItem('corp_agent_id', JSON.stringify(id))
        }
      }
      //  }

      // 解析 URL
    },

    async getUrlAgendId (urls) {
      const url = urls
      const urlParams = new URLSearchParams(new URL(url).search)

      this.corp_agent_id = JSON.parse(sessionStorage.getItem('corp_agent_id'))

      this.code = urlParams.get('code')

      if (this.corp_agent_id && !this.code) {
        sessionStorage.setItem(
          'corp_agent_id',
          JSON.stringify(this.corp_agent_id)
        )
        this.getWebAuthLink()
      } else if (!this.corp_agent_id && !this.code) {
        this.getWebAuthLink()
      } else {
        await this.loginProof()
        // await this.sidebarLogin()
        // await this.configAll()
        // await this.EnterpriseWeChat()
      }

      // if ( this.code && this.corp_agent_id) {
      //      await this.sidebarLogin();
      //       await this.configAll();
      //       await this.EnterpriseWeChat();
      // } else{
      // this.getWebAuthLink()
      // }
    },
    // 获取授权链接
    getWebAuthLink () {
      const url = window.location.href

      const encodedUrl = encodeURIComponent(url)
      getWebAuthLink(this.corp_agent_id, encodedUrl).then((res) => {
        window.location.href = res.data
      })
    },

    // 侧边栏登录
    async sidebarLogin () {
      this.show = true
      try {
        const data = {
          corp_agent_id: this.corp_agent_id,
          user_code: this.user_code,
          work_userid: this.work_userid
        }
        const res = await sidebarLogin(data)
        localStorage.setItem('token', res.data.access_token)
        localStorage.setItem('token_type', res.data.token_type)
      } catch (error) {
      }
    },
    // 验证登录信息
    async loginProof () {
      try {
        const query = {
          corp_agent_id: this.corp_agent_id,
          code: this.code
        }
        const data = await loginProof(query)
        if (data.code === 200) {
          if (!data.data.user_code) {
            this.work_userid = data.data.work_userid
            this.viewFlag = false
          } else if (data.data.user_code) {
            this.user_code = data.data.user_code
            this.work_userid = data.data.work_userid
            await this.sidebarLogin()
            await this.configAll()
            await this.EnterpriseWeChat()
          }
        } else if (data.code === 400) {
          Toast('请稍等')

          this.$router.replace({
            path: '/',
            query: {
              corp_agent_id: this.corp_agent_id
            }
          })
          this.getWebAuthLink()
        }
      } catch (error) {
      }
    },
    // loginAll
    async loginAll () {
      await this.sidebarLogin()
      await this.configAll()
      await this.EnterpriseWeChat()
      this.viewFlag = true
    },
    // 获取config权限数据
    // 总config
    async configAll () {
      await Promise.all([this.getConfig(), this.getAgentConfig()])
    },
    // Config注入
    async getConfig () {
      const url = window.location.href
      const data = {
        corp_agent_id: this.corp_agent_id,
        url: url,
        apis: ['getCurExternalContact', 'sendChatMessage', 'previewFile']
      }
      const res = await getConfig(data)
      this.ConfigData = res.data
      const data1 = this.ConfigData
      sessionStorage.setItem('data1', JSON.stringify(data1))
    },

    // AgentConfig注入
    async getAgentConfig () {
      const url = window.location.href
      const data = {
        corp_agent_id: this.corp_agent_id,
        url: url,
        apis: ['getCurExternalContact', 'sendChatMessage', 'previewFile']
      }

      const res = await getAgentConfig(data)
      this.agentConfigData = res.data

      const data2 = this.agentConfigData
      sessionStorage.setItem('data2', JSON.stringify(data2))
    },
    // 获取外部联系人ID
    async EnterpriseWeChat () {
      this.hetongjilushowflag = false
      const that = this
      const agentConfig = this.agentConfigData
      const Config = this.ConfigData
      // eslint-disable-next-line no-undef
      await wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: Config.appId, // 必填，企业微信的corpID
        timestamp: Config.timestamp, // 必填，生成签名的时间戳
        nonceStr: Config.nonceStr, // 必填，生成签名的随机串
        signature: Config.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: Config.jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      })

      // eslint-disable-next-line no-undef
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })

      // eslint-disable-next-line no-undef
      await wx.ready(function () {
        // eslint-disable-next-line no-undef
        wx.agentConfig({
          corpid: agentConfig.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentConfig.agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: agentConfig.timestamp, // 必填，生成签名的时间戳
          nonceStr: agentConfig.nonceStr, // 必填，生成签名的随机串
          signature: agentConfig.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: agentConfig.jsApiList, // 必填，传入需要使用的接口名称
          success: function (res) {
            // 回调
            // eslint-disable-next-line no-undef
            wx.invoke('getCurExternalContact', {}, async function (res) {
              // eslint-disable-next-line eqeqeq
              if (res.err_msg == 'getCurExternalContact:ok') {
                that.external_userid = res.userId /// /
                sessionStorage.setItem('data3', that.external_userid)
                await that.listInfo()
                that.hetongjilushowflag = true
                that.active = that.$route.query.active || 0
                window.location.reload()
              } else {
                // 错误处理

              }
            })
          },
          fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          }
        })

        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      })
    },

    // 总
    async listInfo () {
      this.show = true
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo && Object.keys(userInfo).length !== 0) {
        this.userWorkInfo = userInfo
        Promise.all([this.getUserRelation(), this.getFollowUser()])
        this.getSelectOrderFirst()
        this.show = false
      } else {
        await this.getUserinfo()
        Promise.all([this.getUserRelation(), this.getFollowUser()])
        this.getSelectOrderFirst()
        this.show = false
        window.history.go(0)
      }
    },

    // 获取用户信息
    async getUserinfo () {
      const data = await getUserinfo()
      const userInfo = data.data
      this.userWorkInfo = userInfo
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    // 获取订单信息
    async getuserOrder () {
      const query = {
        external_userid: this.external_userid,
        limit: this.limit,
        page: this.page
      }
      const { data } = await getuserOrder(query)
      this.total = data.total
      this.orderList.push(...data.list)
    },
    // 清除搜索非绑定订单
    onOrderClear () {
      this.trade_no_show = ''
      this.page = 0
      this.total = 10
      this.orderList = []

      this.onLoad()
    },
    // 搜索非绑定订单
    async onOrderSearch () {
      if (this.trade_no_show === '') {
        return Toast.fail('请输入订单号')
      }
      this.orderList = []
      const query = {
        trade_no_show: this.trade_no_show
      }
      const { data } = await searchOrder(query)
      if (data.list.length > 0) {
        this.orderList = data.list
      } else {
        Toast.fail('并未查询到该订单,请核对')

        this.onOrderClear()
      }
    },
    // 绑定订单
    async bindOrder (item) {
      this.payPrise = item.pay_price
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (!userInfo) {
        this.getUserinfo()
        this.bindOrder(item)
      }
      // if (item.product_id && item.product_id !== 0) {
      //   this.bindOrderQuery.id = item.id
      //   this.bindOrderQuery.trade_no_show = item.trade_no_show
      //   this.bindOrderQuery.user_code = userInfo.user_code
      //   this.bindOrderQuery.external_userid = sessionStorage.getItem('data3')
      //   this.bindOrderQuery.product_id = item.product_id
      //   this.name = `${item.product_name}(${item.price})`
      //   this.radio = item.product_id
      //   this.querenSHow = true
      // } else {
      this.bindOrderQuery.id = item.id
      this.bindOrderQuery.trade_no_show = item.trade_no_show
      this.bindOrderQuery.user_code = userInfo.user_code
      this.bindOrderQuery.external_userid = sessionStorage.getItem('data3')
      this.bindOrderShow = true
      // }
    },
    // 商品列表
    async getProductList () {
      const query = {
        page: this.bindOrderPage,
        limit: this.bindOrderlimit
      }
      const { data } = await getProductList(query)
      this.demos.push(...data.list)
      this.bindOrdertotal = data.total
    },
    // 綁定訂單商品列表
    bindOrderonLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.bindOrderPage = this.bindOrderPage + 1
      this.getProductList()
      // 加载状态结束
      this.bindOrderloading = false

      // 数据全部加载完成
      if (this.demos.length >= this.bindOrdertotal) {
        this.bindOrderonLoadfinished = true
      }
    },
    // 绑定订单单选
    realdCLick (item) {
      this.bindOrderQuery.product_id = item.id
      this.radio = item.id
      this.name = `${item.store_name}(${item.price})`
      if (this.payPrise === item.price) {
        this.bindOrderQuery.payment_type = '1'
        this.paytypeRadio = true
      } else if (this.payPrise < item.price) {
        this.paytypeRadio = false
        this.paytypeRadioA = true
        // eslint-disable-next-line eqeqeq
        if (this.bindOrderQuery.payment_type && this.bindOrderQuery.payment_type == '1') {
          this.bindOrderQuery.payment_type = ''
          Toast.fail('支付金额小于订单金额,无法选择全款,请重新选择')
        }
      } else {
        this.paytypeRadio = false
        this.paytypeRadioA = false
      }
    },

    // 二次确认弹框
    queren () {
      if (this.radio !== '' && this.bindOrderQuery.payment_type !== '') {
        this.querenSHow = true
      } else {
        Toast('请选择完信息后后再确认')
      }
    },

    // 绑定订单关闭事件
    bindOrderclose () {
      this.bindOrderQuery.payment_type = ''
      this.radio = ''
      this.paytypeRadio = false
      this.paytypeRadioA = false
    },
    // 点击确定的事件
    async diaconfirm () {
      this.$toast.loading('请稍等...')

      await orderBindingUser(this.bindOrderQuery)
      this.$toast.clear()
      Toast('绑定成功')
      this.bindOrderShow = false
      this.onOrderClear()
    },
    // 点击取消事件
    diaCancel () {
      this.querenSHow = false
    },
    // 关系详情
    getUserRelation () {
      const query = {
        external_userid: this.external_userid,
        userid: this.userWorkInfo.work_userid
      }
      getUserRelation(query)
        .then((res) => {
          this.userRelation = res.data
          this.loadCustomerInfoByCode(res.data.member_code)
          sessionStorage.setItem('isDealShow', this.userRelation.is_show_deal)
          localStorage.setItem('member_code', res.data.member_code)
          this.userRelationMark = this.userRelation.remark_mobiles[0]
          this.userRelationMarkLast = this.userRelation.remark_mobiles.slice(1)
          this.tabsFlag = false
        })
        .catch((res) => {
          console.log(res, '关系详情的错误')
        })
    },

    // 已添加员工
    getFollowUser () {
      const id = {
        external_userid: this.external_userid
      }
      followUser(id)
        .then((res) => {
          this.followUserList = res.data
        })
        .catch((res) => {
          console.log(res, '已添加员工错误')
        })
    },

    // 跟进记录
    getRecordList () {
      this.showRow = true
      const query = {
        external_userid: this.external_userid,
        userid: this.userWorkInfo.work_userid,
        user_code: this.userWorkInfo.user_code
      }
      recordList(query).then((res) => {
        this.followupRecord = res.data
        this.showRow = false
      })
    },

    // 添加跟进记录
    async followupRecordAdd () {
      const query = {
        external_userid: this.external_userid,
        userid: this.userWorkInfo.work_userid,
        user_code: this.userWorkInfo.user_code,
        record: this.followupRecordValue
      }
      await addRecord(query)
      this.getRecordList()
      this.followupRecordclose()
    },

    followupRecordclose () {
      this.followupRecordShow = false
      this.followupRecordValue = ''
    },

    // 删除跟进记录
    delRecord (id) {
      Dialog.confirm({
        title: '提示',
        message: '是否删除数据?'
      })
        .then(async () => {
          // on confirm
          const query = {
            id
          }
          await delRecord(query)
          this.getRecordList()
        })
        .catch(() => {
          // on cancel
        })
    },

    // 听课记录
    getListenList () {
      this.showRow = true
      const query = {
        external_userid: this.external_userid,
        limit: this.listenLimit,
        page: this.listenPage
      }
      listenList(query).then((res) => {
        this.listenTotal = res.data.total
        this.LectureRecord.push(...res.data.list)
        this.showRow = false
      })
    },
    // 听课记录列表
    onListenLoad () {
      this.listenLimit = 10
      this.listenPage = +this.listenPage + 1
      this.getListenList()
      this.listenloading = false
      if (this.LectureRecord.length >= this.listenTotal) {
        this.listenfinished = true
      }
    },
    // 用户画像
    getUserList () {
      this.userPortraitShow = true
      const id = {
        external_userid: this.external_userid
      }
      getUserList(id).then((res) => {
        this.userPortrait = res.data
        this.userPortraitShow = false
      })
    },

    // 修改用户信息
    eidtRoter () {
      this.$router.push({
        query: {
          external_userid: this.external_userid,
          userid: this.userWorkInfo.work_userid
        },
        path: '/userInfo'
      })
    },
    // 修改用户信息
    userPortraitRouter () {
      this.$router.push({
        query: {
          external_userid: this.external_userid
        },
        path: '/userPor'
      })
    },
    // 编辑标签
    eidtTag () {
      this.$router.push({
        query: {
          external_userid: this.external_userid,
          userid: this.userWorkInfo.work_userid
        },
        path: '/addTag'
      })
    },
    // 用户切换资料tab
    handleClickInfo (name, title) {
      console.info(name, 'name')
      if (name === '表单填写') {
        this.loadQuesList(this.userRelation.member_code)
      }
      if (name === '食疗方案') {
        this.loadSchemeList() // 获取方案列表最新一条
        this.loadProductList() // 获取用户商品列表
      }
      if (name === '客户动态') {
        this.loadUserTrends()
      }
    },
    // 根据客户编号获取问卷调查列表
    loadQuesList () {
      getQuestionList({ member_code: this.userRelation.member_code, external_userid: this.external_userid }).then(res => {
        this.formList = res.data
      })
    },
    // 资料-填写表单-点击详情
    handleLinkToDetail (item) {
      this.$router.push({
        query: {
          formId: item.id
        },
        path: '/healthSheet'
      })
    },
    // 填写表单-重置
    handleReset (item) {
      Dialog.confirm({
        title: '表单重置',
        confirmButtonText: '确定',
        message: `表单重置后，记录将被删除，客户可重新填写表单，确定重置${item.group.group_name}?`
      })
        .then(() => {
          this.show = true
          handleReset({
            group: item.group.id,
            member_code: this.userRelation.member_code,
            external_userid: this.external_userid
          }).then(() => {
            this.show = false
            this.loadQuesList(this.userRelation.member_code)
            Toast('重置成功!')
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    // 根据客户编码获取客户基础信息
    loadCustomerInfoByCode (memberCode) {
      getInfoByMemberCode({ member_code: memberCode, external_userid: this.external_userid }).then(res => {
        this.customerInfo = res.data
      })
    },
    // 用户新建方案
    handleAddScheme () {
      this.$router.push({
        path: '/addSchemeFirst'
      })
    },
    // 获取方案列表 最新的一条
    loadSchemeList () {
      this.show = true
      getSchemeList({
        member_code: this.userRelation.member_code,
        external_userid: this.external_userid,
        page: 1,
        limit: 1
      }).then(res => {
        this.show = false
        this.schemeList = res.data.list
        this.loadProductByMemberCode(this.userRelation.member_code)
      })
    },
    // 点击方案详情查看详情
    handleLinkToScheme (data) {
      this.$router.push({
        query: {
          schemeNo: data.scheme_no
        },
        path: '/schemeDetail'
      })
    },
    // 用户点击更多
    handleLinkMore () {
      this.$router.push({
        path: '/schemeList'
      })
    },
    // 用户点击 未使用方案编辑
    handleLinkToUpdate (data) {
      this.$router.push({
        query: {
          stageId: data.stage_id
        },
        path: '/addSchemeSecond'
      })
    },
    // 用户选择打卡时间类型
    handleSelectClockType (index, data) {
      console.info(data, 'data')
      this.currentDateType = data.type
      this.dateType.forEach(item => {
        item.isActive = false
      })
      this.dateType[index].isActive = true
      const params = {
        member_code: this.userRelation.member_code,
        external_userid: this.external_userid,
        date: '',
        day: '',
        time: []
      }
      if (this.currentDateType === 'date') {
        params.date = this.formatterDate(new Date())
        params.day = ''
        params.time = []
        this.loadClockList(params)
      } else if (this.currentDateType === 'day3') {
        params.day = '3'
        params.date = ''
        params.time = []
        this.loadClockList(params)
      } else if (this.currentDateType === 'day7') {
        params.day = '7'
        params.date = ''
        params.time = []
        this.loadClockList(params)
      } else if (this.currentDateType === 'time') {
        this.showClockPop = true
      } else {
        params.date = ''
        params.day = ''
        params.time = []
      }
    },
    // 处理总共消耗热量
    handleTotalHeat (data) {
      let total = 0
      if (data.length > 0) {
        data.forEach(item => {
          total += Number(item.heat)
        })
      }
      return total
    },
    // 打卡内容展开、收缩
    handleExpandClock (index, data) {
      console.info(data, 'data')
      data.isExpand = !data.isExpand
    },
    // 获取用户打卡记录
    loadClockList (data) {
      this.show = true
      this.clockInInfo = {
        list: [],
        total: 0
      }
      getClockList(data).then(res => {
        res.data.list.forEach(item => {
          item.isExpand = false
        })
        this.clockInInfo = res.data
        if (this.currentDateType === 'date') {
          this.clockInInfo.list[0].isExpand = true
        }
        this.show = false
      }).catch(() => {
        this.show = false
      })
    },
    // 用户点击选择时间
    chooseDate (data) {
      console.info(data, 'type')
      this.currentInput = data
      if (data === 'start') {
        this.showstart = true
        this.showend = false
      } else {
        this.showend = true
        this.showstart = false
      }
    },
    formatter (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    // 确定开始时间
    confirmStart (e) {
      console.info(e, 'e')
      this.startDate = this.formatterDate(e)
      this.showstart = false
    },
    // 确定结束时间
    confirmEnd (e) {
      console.info(e, 'e')
      this.endDate = this.formatterDate(e)
      this.showend = false
    },
    formatterDate (date) {
      const dateObj = new Date(date)
      // 获取年、月、日的值
      const year = dateObj.getFullYear()
      let month = dateObj.getMonth() + 1
      let day = dateObj.getDate()
      // 将月份和日期的值转换为两位数
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }

      // 将结果拼接为YYYY-MM-DD格式
      return year + '-' + month + '-' + day
    },
    // 比较时间区间
    handleConfirmDate () {
      // 判断开始时间不能等于结束时间、
      const start = new Date(this.startDate)
      const end = new Date(this.endDate)
      if (this.startDate === '' || this.endDate === '') {
        Toast('请选择开始时间/结束时间')
        return false
      }
      console.info(start, 'start')
      console.info(end, 'start')
      console.info(start === end, 'wsddf')
      if (this.startDate === this.endDate) {
        Toast('开始时间和结束时间不能相同')
        return false
      }
      if (start > end) {
        Toast('开始时间不能大于结束时间')
        return false
      }
      this.loadClockList({
        member_code: this.userRelation.member_code,
        external_userid: this.external_userid,
        date: '',
        day: '',
        time: [this.startDate, this.endDate]
      })
      this.showClockPop = false
    },
    // 关闭自定义弹出层
    handleClockPopClose () {
      if (this.startDate !== '' && this.endDate !== '') {
        this.loadClockList({
          member_code: this.userRelation.member_code,
          external_userid: this.external_userid,
          date: '',
          day: '',
          time: [this.startDate, this.endDate]
        })
      } else {
        this.clockInInfo = {
          list: [],
          total: 0
        }
      }
      this.showClockPop = false
    },
    // 点击餐食图片预览
    previewImage (data) {
      ImagePreview({
        images: [data],
        closeable: true
      })
    },
    // 获取商品列表
    async loadProductList () {
      const res = await getCourseList({
        store_name: '',
        page: 1,
        limit: 1000
      })
      this.productList = res.data.list
    },
    handleRadioClick (e, product) {
      this.currentProduct = Object.assign({}, product)
    },
    // 用户提交确认绑定课程
    handleSubmitProduct () {
      if (this.currentProductId === '') {
        Toast('请选择需要绑定的商品')
        return false
      }
      this.bindBtnDisable = true
      this.binBtnLoading = true
      Dialog.confirm({
        title: '绑定商品',
        message: `确认绑定${this.currentProduct.store_name} !`
      })
        .then(() => {
          // on confirm
          bindCourseToMemberCode({
            member_code: this.userRelation.member_code,
            external_userid: this.external_userid,
            product_id: this.currentProductId
          }).then(res => {
            Toast('恭喜您,商品绑定成功')
            this.showProductPop = false
            this.loadSchemeList()
            this.bindBtnDisable = false
            this.binBtnLoading = false
          }).catch(() => {
            this.bindBtnDisable = false
            this.binBtnLoading = false
            this.showProductPop = false
          })
        })
        .catch(() => {
          // on cancel
          this.bindBtnDisable = false
          this.binBtnLoading = false
          this.showProductPop = false
        })
    },
    // 获取用户商品列表
    async loadProductByMemberCode (memberCode) {
      const res = await getGoodsByMemberCode({
        member_code: memberCode,
        external_userid: this.external_userid
      })
      this.userProductList = res.data
    },
    // 获取客户动态
    loadUserTrends () {
      this.show = true
      getUserTrends({
        external_userid: this.external_userid,
        limit: this.pageTrendsRequest.limit,
        page: this.pageTrendsRequest.page
      }).then(res => {
        this.show = false
        this.customerTrendsList = res.data.list
        this.userTrends = res.data
        if (this.customerTrendsList.length === res.data.total) {
          this.showLoadMore = false // 没有更多
        } else {
          this.showLoadMore = true
        }
        this.showLoadMore = false
      }).catch(() => {
        this.show = false
      })
    },
    // 加载更多动态
    loadMoreTrends () {
      this.pageTrendsRequest.page++
      this.show = true
      getUserTrends({
        external_userid: this.external_userid,
        limit: this.pageTrendsRequest.limit,
        page: this.pageTrendsRequest.page
      }).then(res => {
        if (this.customerTrendsList.length === res.data.total) {
          this.showLoadMore = false // 没有更多
          this.show = false
          return false
        }
        this.customerTrendsList = this.customerTrendsList.concat(res.data.list)
        this.showLoadMore = true
      }).catch(err => {
        console.log(err)
        this.show = false
      })
    },
    // 客户动态点击
    handleLink (data) {
      console.info(data, 'data')
      window.open(data.url, '_blank')
    }
  }
}
