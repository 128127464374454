// import Vue from 'vue'
import axios from 'axios'
import { Toast } from 'vant'
import router from '../router/index'
// 创建axios新实例
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // 接口根地址
  timeout: 100010 // 超时时间
})

/**
 * 请求拦截器
 */
request.interceptors.request.use(
  (config) => {
    // 封装token鉴权
    const token = localStorage.getItem('token')
    const tokenType = localStorage.getItem('token_type')
    if (token) {
      config.headers.Authorization = `${tokenType}${token}`
    // eslint-disable-next-line camelcase
    // const token_type = 'Bearer'
    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiJzaWRlYmFyIiwiaWF0IjoxNzExMDcxNTY2LCJuYmYiOjE3MTEwNzE1NjUsImV4cCI6MTcxMTExNDc2NiwiZGF0YSI6eyJpZCI6MjQsInVzZXJfY29kZSI6IjEwMDAwMDI0IiwibmFtZSI6Ilx1OTBlZFx1NGU5MVx1OTZmNyIsImVtYWlsIjoiIiwibW9iaWxlIjoiMTg2MjE1NjU5MzQiLCJpZGNhcmQiOiIiLCJhdmF0YXIiOiJodHRwczovL3dld29yay5xcGljLmNuL3d3cGljM2F6LzE0MDEyOV91QjhHV0k4SFNIMk0wcklfMTcwMzcyOTU0Mi8wIiwiZ2VuZGVyIjowLCJyZWFsX25hbWUiOiIiLCJ0aXRsZSI6IiIsInVuaW9uaWQiOiIiLCJzdGF0dXMiOjEsImpvaW5fdGltZSI6IjAwMDAtMDAtMDAiLCJsZWF2ZV90aW1lIjoiMDAwMC0wMC0wMCIsImpvYl9udW1iZXIiOiIiLCJzdGFnZV9uYW1lIjoiIiwiY2hhbm5lbF9udW0iOiIiLCJpc19sb2NrIjoxLCJyZWxhdGVfdGFiIjoiIiwiY2FsbF9tb2JpbGUiOiIiLCJsYXN0X3Rva2VuIjoiIiwiY29ycF9pZCI6Ind3MmM0YTE4N2VhYjdmOGZlYix3d2Q2MGUyMzA5Nzg3ZjUzZTYiLCJjb3JwX3FyX2NvZGUiOiJodHRwczovL29wZW4ud29yay53ZWl4aW4ucXEuY29tL3d3b3Blbi91c2VyUVJDb2RlP3Zjb2RlPXZjZTJiNmQ5NjNmNmE1NjUxYiIsInR5cGUiOjAsImNyZWF0ZWRfYXQiOiIyMDI0LTAzLTExIDE3OjE1OjQ1IiwidXBkYXRlZF9hdCI6IjIwMjQtMDMtMjAgMTY6MDU6MjEiLCJjb3JwX2FnZW50X2lkIjoyLCJzY29wZSI6InRva2VuIn19.YZYxmsUIJ-BiLEKVqwcsjuWnwnB0_iQHNNX2qMYBqEQ'
    // eslint-disable-next-line camelcase
    // config.headers.Authorization = `${token_type} ${token}`
    }
    return config
  },
  (err) => Promise.reject(err)
)

/**
 * 响应拦截器
 */
request.interceptors.response.use(
  (res) => {
    // 过滤数据
    return res.data
  },
  async (err) => {
    const { response } = err

    if (response) {
      const status = response.data.code
      switch (status) {
        case 404:
          Toast({
            message: '资源不存在 404',
            position: 'top'
          })
          break

        case 403:
          Toast({
            message: '拒绝访问 403',
            position: 'top'
          })
          break

        case 401:
          Toast({
            message: `${response.data.msg}`,
            position: 'top'
          })
          if (response.data.msg === '鉴权失败请登录') {
            Toast({
              message: `${response.data.msg}`,
              position: 'top'
            })
          }
          localStorage.removeItem('token')
          localStorage.removeItem('token_type')
          localStorage.removeItem('userInfo')
          router.replace(
            {
              path: '/tiaozhuan'

            }
          )
          break
        case 500:
          Toast({
            message: '服务器错误 500',
            position: 'top'
          })
          break
        case 400:
          Toast({
            message: `${response.data.msg}`,
            position: 'top'

          })
          break

        default:
          Toast({
            message: `${response.data.msg}`,
            position: 'top'
          })

          break
      }
    } else {
      // 服务器崩溃问题或是网络问题
      if (!window.navigator.onLine) {
        Toast({
          message: '当前网络不可用,请检查你的网络连接 ',
          position: 'top'
        })
        return
      } else {
        Toast({
          message: `连接服务器错误 ${err?.message}`,
          position: 'top'
        })

        return Promise.reject(err)
      }
    }
    return Promise.reject(err)
  }
)

export default request
