<template>
  <div class="">
    <router-view />
    <van-overlay :style="{ minHeight: boxHeight + 'px' }" :show="show" style="z-index: 999;position: fixed">
    </van-overlay>

    <van-tabbar route v-model="active">
      <van-tabbar-item replace to="/HomeView" name="客户">
        <div>
          <template>
            <img src="../../img/renqunB.png" class="imgsA" v-if="active === '客户'" />
            <img src="../../img/renqunnone.png" class="imgsA" v-else />
          </template>
          <div>客户</div>
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/courselist" name="商品">
        <div>
          <template>
            <img src="../../img/shangpinA.png" alt="" v-if="active === '商品'" class="imgsA" />
            <img src="../../img/shangpinB.png" alt="" v-else class="imgsA" />
          </template>
          <div>商品</div>
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/contract" name="合同">
        <div>
          <template>
            <img src="../../img/activeHeTong.png" alt="" v-if="active === '合同'" class="imgsA" />
            <img src="../../img/hetong.png" alt="" v-else class="imgsA" />
          </template>
          <div>合同</div>
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tools" name="工具">
        <div>
          <template>
            <img src="../../img/tools-active.png" alt="" v-if="active === '工具'" class="imgsA" />
            <img src="../../img/tools.png" alt="" v-else class="imgsA" />
          </template>
          <div>工具</div>
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/mymine" name="我的">
        <div>
          <template>
            <img src="../../img/renyuanActive.png" alt="" v-if="active === '我的'" class="imgsA" />
            <img src="../../img/renyuan.png" alt="" v-else class="imgsA" />
          </template>
          <div>我的</div>
        </div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data () {
    return {
      boxHeight: 0,
      active: '',
      isLogin: true,
      show: true
    }
  },
  computed: {},
  watch: {},
  methods: {
    setBoxHeight () {
      this.boxHeight = window.innerHeight // 获取当前窗口高度并设置盒子高度
    }
  },
  created () {
    setTimeout(() => {
      this.show = false
    }, 1000)
  },
  mounted () {
    this.setBoxHeight() // 初始化盒子高度
    window.addEventListener('resize', this.setBoxHeight) // 监听窗口大小变化
  },
  beforeDestroy () { }
}
</script>
<style lang="scss" scoped></style>
